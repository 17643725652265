import Slider from 'javascripts/globals/slider';
import { BREAKPOINTS } from 'javascripts/constants';

const mql = [
  window.matchMedia(`(min-width: ${BREAKPOINTS.m})`),
  window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isL = mql[1].matches;

  document
    .querySelectorAll('.mission-card-slider')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.mission-card-slider__item');
      if ($items && ($items.length <= 2) && isL) {
        return;
      }

      componentStore.push(new Slider($slider, {
        rewind: true,
        infinite: false,
        sliderFrame: 'mission-card-slider__frame',
        slidesContainer: 'mission-card-slider__items',
        sliderSlide: 'mission-card-slider__item',
        sliderControlsframe: 'mission-card-slider__controls',
        sliderControlsframemethod: 'append',
        slidesToScroll: 1,
        slidesFocusable: false,
      }));
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

onMediaQueryChange();

export default onMediaQueryChange;
