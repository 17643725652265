import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from '../../_particles/icon/generate-icon';

export default function iconButton({
  tag, type, title = false, icon, link,
  disabled = false, classes = false, tabindex = '0', ariaHidden = false, secondary = false,
}) {
  let iconButtonAttrs;

  const iconButtonClasses = classNames(
    'icon-button',
    secondary && 'icon-button--secondary',
    classes && classes,
  );

  const iconButtonAdditionalAttrs = {
    tabindex,
    'aria-hidden': ariaHidden,
  };

  if (link) {
    iconButtonAttrs = {
      className: iconButtonClasses,
      href: link,
      attrs: iconButtonAdditionalAttrs,
    };
  } else {
    iconButtonAttrs = {
      className: iconButtonClasses,
      type: type || 'button',
      disabled,
      attrs: iconButtonAdditionalAttrs,
    };
  }
  return h(`${(link && 'a') || tag || 'button'}`, iconButtonAttrs, generateIcon({ title, icon, classes: ['icon-button__icon'] }));
}
