import throttle from 'javascripts/utils/throttle';
import icon from 'components/_particles/icon/generate-icon';
import h from 'hyperscript';
import Headroom from 'headroom.js';

export default class StageFaqAnchors {
  constructor($stageFaqAnchors) {
    this.$el = $stageFaqAnchors;
    this.$anchors = this.$el.querySelectorAll('.stage-faq__anchor');
    this.$anchorsInner = this.$el.querySelector('.stage-faq__anchors-inner');
    this.$anchorTargets = {};

    this.anchorsInnerScrollBinded = this.anchorsInnerScroll.bind(this);
    this.$anchorsInner.addEventListener('scroll', throttle(this.anchorsInnerScrollBinded), { passive: true });

    this.$anchors.forEach(($anchor) => {
      $anchor.addEventListener('click', this.anchorClick.bind(this));
    });

    this.openByHash();
    this.anchorsInnerControls();
  }

  anchorsInnerControls() {
    // Add control prev
    this.$anchorsControlPrev = h(
      '.stage-faq__anchors-control',
      h('button.stage-faq__anchors-control-button.stage-faq__anchors-control-button--prev',
        {
          type: 'button',
          onclick: () => {
            this.$anchorsInner.scrollLeft -= 40;
          },
        },
        icon({
          icon: 'nav-left',
        })),
    );

    this.$el.prepend(this.$anchorsControlPrev);

    // Add control next
    this.$anchorsControlNext = h(
      '.stage-faq__anchors-control',
      h('button.stage-faq__anchors-control-button.stage-faq__anchors-control-button--next',
        {
          type: 'button',
          onclick: () => {
            this.$anchorsInner.scrollLeft += 40;
          },
        },
        icon({
          icon: 'nav-right',
        })),
    );

    this.$el.append(this.$anchorsControlNext);

    // const $target = event.target.closest('.stage-faq__anchors-control-button');

    // if ($target.classList.contains('stage-faq__anchors-control-button--next')) {
    //   this.$anchorsInner.scrollLeft += 40;
    // } else {
    //   this.$anchorsInner.scrollLeft -= 40;
    // }
  }

  anchorsInnerScroll() {
    let anchorsTotalWidth = 0;
    this.anchorsInnerScrollLeft = this.$anchorsInner.scrollLeft;

    this.$anchors.forEach(($anchor) => {
      anchorsTotalWidth += $anchor.offsetWidth;
    });

    const anchorsInnerWidth = this.$anchorsInner.offsetWidth;
    const anchorsInnerScrollMax = (anchorsTotalWidth - anchorsInnerWidth) - 1;

    if (this.anchorsInnerScrollLeft > 0) {
      this.$anchorsControlPrev.querySelector('.stage-faq__anchors-control-button').setAttribute('style', 'display: block;');
    } else {
      this.$anchorsControlPrev.querySelector('.stage-faq__anchors-control-button').setAttribute('style', 'display: none;');
    }

    if (this.anchorsInnerScrollLeft < anchorsInnerScrollMax) {
      this.$anchorsControlNext.querySelector('.stage-faq__anchors-control-button').setAttribute('style', 'display: block;');
    } else {
      this.$anchorsControlNext.querySelector('.stage-faq__anchors-control-button').setAttribute('style', 'display: none;');
    }
  }

  recoverFaqAnchors() {
    // Scroll down some pixels with a slight delay to recover the anchors covered by header element
    setTimeout(() => {
      let scrollByAmount = 1;
      const headroomHeaderPinned = document.body.classList.contains('headroom-header-pinned');
      // eslint-disable-next-line no-mixed-operators
      scrollByAmount = (headroomHeaderPinned === true) && -200 || 1;
      window.scrollBy(0, scrollByAmount);
    }, 500);
  }

  anchorClick(event) {
    document.querySelector('.header').classList.add('header--slide-down--disabled');
    this.recoverFaqAnchors();
    this.anchorActive(event.target.closest('.stage-faq__anchor'));
  }

  anchorActive($target) {
    this.$anchors.forEach(($anchor) => {
      $anchor.classList.remove('stage-faq__anchor--active');
    });

    $target.classList.add('stage-faq__anchor--active');
    $target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }

  openByHash() {
    if (window.location.hash) {
      this.recoverFaqAnchors();
    }
  }
}

document.querySelectorAll('.stage-faq__anchors').forEach(($el) => {
  const $stageFaq = document.querySelector('.stage-faq');
  const stageFaqRect = $stageFaq.getBoundingClientRect();

  const headroom = new Headroom($el, {
    offset: stageFaqRect.height,
    tolerance: {
      up: 30,
      down: 0,
    },
    classes: {
      initial: 'stage-faq__anchors--animated',
      pinned: 'stage-faq__anchors--slide-down',
      unpinned: 'stage-faq__anchors--slide-up',
      top: 'stage-faq__anchors--top',
      notTop: 'stage-faq__anchors--not-top',
    },
  });
  headroom.init();

  // eslint-disable-next-line no-unused-vars
  const stageFaqAnchors = new StageFaqAnchors($el);
});
