export default class Quiz {
  constructor($el) {
    this.$el = $el;
    this.$answers = this.$el.querySelectorAll('.quiz__answer');

    this.onAnswerClickBinded = this.onAnswerClick.bind(this);

    this.$answers.forEach(($answer) => {
      $answer.addEventListener('click', this.onAnswerClickBinded);
    });
  }

  onAnswerClick(e) {
    const $targetAnswer = e.target.closest('.quiz__answer');

    this.$answers.forEach(($answer) => {
      $answer.classList.remove('quiz__answer--open', 't-dark');
    });

    $targetAnswer.classList.add('quiz__answer--open', 't-dark');
  }
}

document.querySelectorAll('.js-quiz').forEach($element => new Quiz($element));
