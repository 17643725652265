import Slider from 'javascripts/globals/slider';

const mql = [
  window.matchMedia('(min-width: 480px)'),
  window.matchMedia('(min-width: 768px)'),
  window.matchMedia('(min-width: 1203px)'),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  document
    .querySelectorAll('.stage-hero--slider')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.stage-hero__slider-item');
      if ($items && ($items.length < 2)) {
        return;
      }

      componentStore.push(new Slider($slider, {
        rewind: true,
        infinite: 1,
        sliderFrame: 'stage-hero__slider-frame',
        slidesContainer: 'stage-hero__slider',
        sliderSlide: 'stage-hero__slider-item',
        sliderControlsframe: 'stage-hero__slider-controls',
        sliderControlsframemethod: 'append',
        slidesToScroll: 1,
        slidesFocusable: false,
        autoplay: true,
        ease: 'cubic-bezier(.75,.28,.29,.7)',
        slideSpeed: 3000,
      }));
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

document.addEventListener('DOMContentLoaded', () => {
  onMediaQueryChange();
}, false);
