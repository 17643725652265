import Headroom from 'headroom.js';
import NavigationOverlay from './header-navigation';
import HeaderFlyout from './header-flyout';
import ToggleButton from './header-toggle';


// Navigation toggle button
document
  .querySelectorAll('.header__mobile-menu-trigger')
  .forEach(
    $toggleButton => new ToggleButton($toggleButton, { closeButton: '.header__mobile-close', initialFocus: '.header__navigation-link' }),
  );

// Search toggle button
document
  .querySelectorAll('.header__search-button')
  .forEach(
    $toggleButton => new ToggleButton($toggleButton, {
      initialFocus: '.header__search-form-input',
      closeButton: '.header__search-close-btn',
    }),
  );

// Headroom Js
const myElement = document.querySelector('header');
const headroom = new Headroom(myElement, {
  offset: 200,
  tolerance: {
    up: 30,
    down: 0,
  },
  classes: {
    initial: 'header--animated',
    pinned: 'header--slide-down',
    unpinned: 'header--slide-up',
    top: 'header--top',
    notTop: 'header--not-top',
  },
  onPin: () => {
    document.body.classList.add('headroom-header-pinned');
  },
  onUnpin: () => {
    document.body.classList.remove('headroom-header-pinned');
  },
});

document.querySelectorAll('.header').forEach(() => {
  headroom.init();
});

document.querySelectorAll('.header__navigation-inner').forEach(($navigation) => {
  const navigationOverlay = new NavigationOverlay($navigation);
  const headerFlyout = new HeaderFlyout($navigation);
  const mql = window.matchMedia('(min-width: 1024px)');

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;
    if (isDesktop) {
      navigationOverlay.deinit();
      headerFlyout.init();
    } else {
      navigationOverlay.init();
      headerFlyout.deinit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});

// User nav
const userNav = document.querySelector('.header__user-nav');
if (userNav) {
  const userNavToggle = userNav.querySelector('.header__user-nav__toggle');
  const userNavExpanded = 'header__user-nav--expanded';

  const toggleMenu = () => {
    userNav.classList.toggle(userNavExpanded);
    userNavToggle.setAttribute('aria-expanded', userNav.classList.contains(userNavExpanded));
  };
  userNavToggle.addEventListener('click', toggleMenu);

  // Click outside
  const clickOutsideMobileMenu = (event) => {
    const isClickInside = userNav.contains(event.target);
    if (!isClickInside) {
      userNav.classList.remove(userNavExpanded);
      userNavToggle.removeAttribute('aria-expanded', userNav.classList.contains(userNavExpanded));
    }
  };
  document.addEventListener('click', clickOutsideMobileMenu);
}
