import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from '../../_particles/icon/generate-icon';

export default function button({
  tag,
  type,
  title = '',
  style = 'primary',
  icon,
  link,
  transparent = false,
  disabled = false,
  classes = false,
  text = '',
}) {
  return h(`${(link && 'a') || tag || 'button'}`, {
    className: classNames(
      'button',
      transparent && 'button--transparent',
      style && `button--${style}`,
      classes && classes,
    ),
    type: type || (!link && 'button'),
    href: link,
    disabled,
  }, generateIcon({ title, icon, classes: ['button__icon'] }), h('span.button__text', text));
}
