export default class FilterCheckboxList {
  constructor($element, options = {}) {
    this.$element = $element;

    this.options = {
      classes: {
        filterCheckboxListButton: 'filter-checkbox-list__dropdown-button',
        filterCheckboxListSections: 'filter-checkbox-list__sections',
        filterCheckboxListSection: 'filter-checkbox-list__section',
        filterCheckboxListSectionItem: 'filter-checkbox-list__section-item',
      },
      ...options,
    };

    this.$filterCheckboxListButton = this.$element.querySelector(`.${this.options.classes.filterCheckboxListButton}`);
    this.$filterCheckboxListSection = this.$element.querySelector(`.${this.options.classes.filterCheckboxListSection}`);
    this.$filterCheckboxListSections = this.$element.querySelector(`.${this.options.classes.filterCheckboxListSections}`);
    this.$filterCheckboxListSectionItems = this.$element.querySelectorAll(`.${this.options.classes.filterCheckboxListSectionItem}`);

    this.$filterCheckboxListButton.addEventListener('click', this.toggleFilter.bind(this));
    this.$filterCheckboxListSectionItems.forEach((item) => {
      item.addEventListener('click', this.closeFilter.bind(this));
    });

    this.bindedOutsideClick = this.outsideClick.bind(this);
    document.addEventListener('click', this.outsideClick.bind(this));
  }

  toggleFilter() {
    // The actual "opening" / "closing" is done via CSS modifier class, this just handles
    // repeated clicks on the button or list items
    const filterIsClosed = this.$filterCheckboxListButton.getAttribute('aria-expanded') !== 'true';

    if (filterIsClosed) {
      this.$filterCheckboxListButton.setAttribute('aria-expanded', 'true');
      this.$filterCheckboxListSections.classList.add('filter-checkbox-list__sections--open');
      this.$filterCheckboxListSections.classList.remove('filter-checkbox-list__sections--closed');
    } else {
      this.closeFilter();
    }
  }

  closeFilter() {
    this.$filterCheckboxListButton.setAttribute('aria-expanded', 'false');
    this.$filterCheckboxListSections.classList.add('filter-checkbox-list__sections--closed');
    this.$filterCheckboxListSections.classList.remove('filter-checkbox-list__sections--open');
  }

  outsideClick(event) {
    const filterCheckboxSections = event.target.closest(`.${this.options.classes.filterCheckboxListSections}`);

    if (filterCheckboxSections === null) {
      this.closeFilter();
    }
  }
}

document.querySelectorAll('.js-filter-checkbox-list').forEach(
  $filterCheckboxList => new FilterCheckboxList($filterCheckboxList),
);
