document.querySelectorAll('.map').forEach((wrap) => {
  const dropdown = wrap.querySelector('.map__dropdown');
  const toggleButton = wrap.querySelector('.map__dropdown__toggle');
  const toggleDropdown = () => {
    dropdown.classList.toggle('map__dropdown--expanded');
    if (dropdown.classList.contains('map__dropdown--expanded')) {
      toggleButton.setAttribute('aria-expanded', true);
    } else {
      toggleButton.setAttribute('aria-expanded', false);
    }
  };
  toggleButton.addEventListener('click', toggleDropdown);
});
