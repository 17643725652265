import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Quickinfo {
  constructor($quickinfo) {
    this.$el = $quickinfo;
    this.$button = $quickinfo.querySelector('.quickinfo__button');
    this.$panel = document.getElementById(this.$button.getAttribute('aria-controls'));
    this.$panelContent = this.$panel.querySelector('.quickinfo__content');
    this.$closeButton = this.$panel.querySelector('.quickinfo__close');
    this.$mainTemplate = document.querySelector('#page');
    this.$documentRoot = document.querySelector(':root');

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$button.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    this.DOMrepositionQuickinfo();

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });
  }

  DOMrepositionQuickinfo() {
    document.body.appendChild(this.$panel);
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$button.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  panelClick(event) {
    if (event.target.closest('.quickinfo__content')) return;

    this.closePanel();
  }

  closePanel(event) {
    if (event) {
      event.preventDefault();
    }

    // Change toggle button
    this.$button.setAttribute('aria-expanded', 'false');

    // Close Panel
    this.$panel.classList.remove('quickinfo__panel--open');

    // Main contents back to the left
    this.$mainTemplate.removeAttribute('style');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    this.$closeButton.removeEventListener('click', this.bindedCloseClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
    clearAllBodyScrollLocks();
    this.$documentRoot.classList.remove('u-scroll-x-locked');
  }

  openPanel() {
    // Change toggle button
    this.$button.setAttribute('aria-expanded', 'true');

    // Push main contents to the right
    this.$mainTemplate.style.transform = 'translateX(48rem)';

    // Show panel
    this.$panel.classList.add('quickinfo__panel--open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Disable scrolling
    disableBodyScroll(this.$panel);
    this.$documentRoot.classList.add('u-scroll-x-locked');

    // Enable focus trap
    this.focusTrap.activate();
  }
}

// Discipline Selector toggle button
document.querySelectorAll('.quickinfo').forEach($element => new Quickinfo($element));
