document.querySelectorAll('.contact-overview--loadmore').forEach((wrap) => {
  const loadMoreButton = wrap.querySelector('.contact-overview__loadmore-button .button');
  let currentItems = 6;
  const loadMore = () => {
    const elementList = [...wrap.querySelectorAll('.contact-overview__item')];
    for (let i = currentItems; i < currentItems + 6; i += 1) {
      if (elementList[i]) {
        elementList[i].style.display = 'block';
      }
    }
    currentItems += 6;
    if (currentItems >= elementList.length) {
      loadMoreButton.style.display = 'none';
    }
  };
  loadMoreButton.addEventListener('click', loadMore);
});
