document.querySelectorAll('.js-hide-show').forEach(($link) => {
  const $target = document.getElementById($link.getAttribute('aria-controls'));

  if ($target) {
    $link.addEventListener('click', () => {
      let expanded = 'false';

      if ($link.getAttribute('aria-expanded') === 'true') {
        expanded = 'false';
      } else {
        expanded = 'true';
      }

      if ($target.getAttribute('hidden')) {
        $target.removeAttribute('hidden');
      } else {
        $target.setAttribute('hidden', 'hidden');
      }

      $link.setAttribute('aria-expanded', expanded);

      if (expanded === 'true') {
        $link.blur();
        $target.scrollIntoView();
      }
    });
  }
});
