document.querySelectorAll('.filter').forEach((filter) => {
  const toggleButton = filter.querySelector('.filter__toggle');
  const classExpanded = 'filter--expanded';

  // Toggle filter
  const toggleFilter = (event) => {
    // Close all filter dropdowns except for the current one
    document.querySelectorAll('.filter').forEach((allFilter) => {
      const allFilterToggleButton = allFilter.querySelector('.filter__toggle');
      if (event.target !== allFilterToggleButton) {
        allFilter.classList.remove(classExpanded);
        allFilterToggleButton.setAttribute('aria-expanded', false);
      }
    });

    // Open or close the currently clicked filter
    if (filter.classList.contains(classExpanded)) {
      filter.classList.remove(classExpanded);
      toggleButton.setAttribute('aria-expanded', false);
    } else {
      filter.classList.add(classExpanded);
      toggleButton.setAttribute('aria-expanded', true);
    }
    event.stopPropagation();
    return false;
  };
  toggleButton.addEventListener('click', toggleFilter);

  // Close filter on click outside
  const clickOutsideFilter = (event) => {
    const isClickInside = filter.contains(event.target);
    if (!isClickInside) {
      filter.classList.remove(classExpanded);
    }
  };
  document.addEventListener('click', clickOutsideFilter);
});
