import MasonryLayout from 'masonry-layout';
import { CountUp } from 'countup.js';

export default class Masonry {
  constructor($masonry) {
    this.$masonry = $masonry;
    this.$itemsContainer = this.$masonry.querySelector('.future-skills__items');
    this.$items = this.$itemsContainer.querySelectorAll('.future-skills__item');
    this.$mainItems = this.$itemsContainer.querySelectorAll('.future-skills__item--main');
    this.$subItems = this.$itemsContainer.querySelectorAll('.future-skills__item--sub');
    this.hasItems = this.$items.length;

    if (!this.hasItems) return;

    this.initMasonryLayout();

    this.itemClickBinded = this.itemClick.bind(this);
    this.$mainItems.forEach(($item) => {
      if ($item.classList.contains('future-skills__item--border')) {
        $item.addEventListener('click', this.itemClickBinded);
      }
    });
  }

  initMasonryLayout() {
    this.masonryLayout = new MasonryLayout(this.$itemsContainer, {
      gutter: 0,
    });
  }

  itemClick(e) {
    const $mainItemClicked = e.target.closest('.future-skills__item');
    const $mainItemCurrActive = this.$itemsContainer.querySelector('.future-skills__item--active');

    if ($mainItemCurrActive) {
      const mainItemCurrActiveId = $mainItemCurrActive.getAttribute('id');
      const $mainItemCurrActiveSubItems = this.$itemsContainer.querySelectorAll(`*[data-ref="${mainItemCurrActiveId}"]`);

      $mainItemCurrActiveSubItems.forEach(($item) => {
        $item.classList.remove('future-skills__item--sub-visible');
      });
    }

    if ($mainItemClicked === $mainItemCurrActive) {
      this.$mainItems.forEach(($item) => {
        $item.classList.remove('future-skills__item--active');
        $item.classList.remove('future-skills__item--inactive');
      });

      this.masonryLayout.layout();

      return;
    }

    this.$mainItems.forEach(($item) => {
      $item.classList.remove('future-skills__item--active');
      $item.classList.add('future-skills__item--inactive');
    });

    const mainItemId = $mainItemClicked.getAttribute('id');
    const $mainItemClickedSubItems = this.$itemsContainer.querySelectorAll(`*[data-ref="${mainItemId}"]`);

    $mainItemClicked.classList.remove('future-skills__item--inactive');
    $mainItemClicked.classList.add('future-skills__item--active');

    // Show all subitems that belong to main item
    $mainItemClickedSubItems.forEach(($item) => {
      $item.classList.toggle('future-skills__item--sub-visible');
    });

    // Trigger Masonry layout to reposition items
    this.masonryLayout.layout();
  }
}

document.querySelectorAll('.future-skills').forEach($el => new Masonry($el));

const $countupValueEls = document.querySelectorAll('.future-skills__item-headline-countup');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      const $target = entry.target;

      // The count-up animation
      const value = $target.textContent;
      const options = {
        decimalPlaces: $target.dataset.decimalplaces,
        decimal: $target.dataset.decimal,
        duration: $target.dataset.duration,
        useGrouping: false,
      };

      const countUp = new CountUp($target, value, options);
      countUp.start();

      // observer.unobserve($target);
    }
  });
});

$countupValueEls.forEach(($countupValueEl) => {
  observer.observe($countupValueEl);
});
