/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
import icon from 'components/_particles/icon/generate-icon';
import h from 'hyperscript';
import randomId from 'javascripts/utils/random-id';
import Tooltip from '../../atoms/tooltip/tooltip';

document.querySelectorAll('.link-tooltip').forEach($linkTooltip => {
  const text = $linkTooltip.getAttribute('data-tooltip');
  const linkHref = $linkTooltip.getAttribute('data-link');
  const linkText = $linkTooltip.getAttribute('data-link-text');
  const headline = $linkTooltip.getAttribute('data-headline');
  const openOnHover = $linkTooltip.getAttribute('data-hover');
  const id = `tooltip-${randomId()}`;

  if (!text) {
    return null;
  }

  $linkTooltip.removeAttribute('title');
  $linkTooltip.setAttribute('aria-controls', id);
  if (!$linkTooltip.hasAttribute('aria-label')) {
    $linkTooltip.setAttribute('aria-label', 'Begriffserklärung anzeigen');
  }

  // Templates
  const $headline = h('h3.link-tooltip__headline', headline);

  const $text = h(
    'span.link-tooltip__content',
    { attrs: { tabindex: '0' } },
    text,
  );
  const $arrow = h('.tooltip__arrow', {
    attrs: { 'x-arrow': '', 'data-popper-arrow': 'true' },
  });

  let $tooltipLink;
  if (linkText && linkHref) {
    $tooltipLink = h('a.link.link-tooltip__link', { href: linkHref }, linkText);
  }

  const $close = h(
    'button.tooltip__close.link-tooltip__close',
    {
      type: 'button',
      title: 'Schließen',
      hidden: true,
    },
    icon({
      icon: 'close',
    }),
  );

  const $tooltipPopup = h(
    `.tooltip__popup.link-tooltip__tooltip#${id}`,
    {
      attrs: {
        'aria-hidden': 'true',
      },
    },
    $headline,
    $text,
    $tooltipLink,
    $arrow,
    $close,
  );

  $linkTooltip.appendChild($tooltipPopup, $linkTooltip);

  if ($tooltipLink) {
    $tooltipLink.addEventListener('click', event => {
      event.stopPropagation();
    });
  }

  $linkTooltip.addEventListener('click', event => {
    event.preventDefault();
  });

  // Usinf the wrapper as a trigger to keep the tooltip open when hovering the popup
  const tooltip = new Tooltip($linkTooltip, $tooltipPopup, {
    closeButtonClass: 'button.link-tooltip__close',
    openOnHover: openOnHover ? openOnHover === 'true' : true,
    closeOnFocusOut: true,
    placement: 'top',
    orientations: ['top', 'top'],
    onClick: () => {
      $tooltipLink?.focus();
    },
  });

  return tooltip;
});
