import getTarget from 'javascripts/utils/get-target';
import detectPrefixes from 'javascripts/utils/detect-prefixes';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';

const prefixes = detectPrefixes();

export default class NavigationOverlay {
  dontFocusBackToMoveLink = true;

  constructor($navigation) {
    this.$navigation = $navigation;
    this.$main = $navigation.querySelector('.header__navigation-level-1-ul');

    // Events
    this.onMoveLinkClickBinded = this.onMoveLinkClick.bind(this);
    this.onBackLinkClickBinded = this.onBackLinkClick.bind(this);
    this.onFocusinBinded = this.onFocusin.bind(this);
  }

  init() {
    this.$navigation.addEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.addEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.addEventListener('focusin', this.onFocusinBinded);

    // Add aria attributes
    this.$navigation
      .querySelectorAll(
        '.header__navigation-link.navigation__move, .navigation__submenu-item-link.navigation__move',
      )
      .forEach($el => $el.setAttribute('aria-expanded', 'false'));
  }

  deinit() {
    this.$navigation.removeEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.removeEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.removeEventListener('focusin', this.onFocusinBinded);

    // Reset navigation height
    this.$navigation.style.height = '';

    // Reset navigation classnames
    this.$navigation.removeAttribute('class');
    this.$navigation.classList.add('header__navigation-inner');

    // Remove inline styles
    this.$main.removeAttribute('style');

    // Find open submenus
    this.$navigation
      .querySelectorAll('.navigation__submenu--current')
      .forEach(($submenu) => {
        $submenu.classList.remove('navigation__submenu--current');
      });

    // Remove aria attributes
    this.$navigation
      .querySelectorAll(
        '.navigation__item-link.navigation__move, .navigation__submenu-item-link.navigation__move',
      )
      .forEach($el => $el.removeAttribute('aria-expanded'));
  }

  adjustNavigationHeight() {
    let $reference;

    if (this.$navigation.classList.contains('navigation--level-6')) {
      $reference = this.$navigation.querySelector(
        '.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current',
      );
    } else if (this.$navigation.classList.contains('navigation--level-5')) {
      $reference = this.$navigation.querySelector(
        '.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current',
      );
    } else if (this.$navigation.classList.contains('navigation--level-4')) {
      $reference = this.$navigation.querySelector(
        '.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current',
      );
    } else if (this.$navigation.classList.contains('navigation--level-3')) {
      $reference = this.$navigation.querySelector(
        '.navigation__submenu--current .navigation__submenu--current',
      );
    } else if (this.$navigation.classList.contains('navigation--level-2')) {
      $reference = this.$navigation.querySelector(
        '.navigation__submenu--current',
      );
    }

    if ($reference) {
      const dimensions = $reference.getBoundingClientRect();
      this.$main.style.height = `${dimensions.height}px`;
    } else {
      this.$main.removeAttribute('style');
    }
  }

  onMoveLinkClick(event) {
    const $moveLink = getTarget(event.target, '.header__navigation-move');

    if ($moveLink) {
      event.preventDefault();

      const $submenu = $moveLink.parentNode.querySelector(
        '.header__navigation-submenu',
      );

      // Make submenu visible
      if ($submenu) {
        // Already active?
        if ($submenu.classList.contains('header__navigation-submenu--current')) {
          return;
        }
        $submenu.classList.add('header__navigation-submenu--current');
      }

      // Move 1 level down
      const navigationClasses = this.$navigation.className;
      const whichLevel = parseInt(
        navigationClasses.substr(navigationClasses.length - 1),
        10,
      );
      this.$navigation.className = `header__navigation-inner header__navigation--level-${whichLevel + 1}`;

      // Set aria attribute
      $moveLink.setAttribute('aria-expanded', 'true');

      // Set focus to the first element
      const setFocus = () => {
        const $firstElement = $submenu.querySelector(
          '.header__navigation-submenu-item:not(.header__navigation-submenu-item--back)',
        );
        const $link = $firstElement.querySelector(
          '.header__navigation-submenu-overview-link',
        );
        unvisibleFocus($link);
        this.$navigation.removeEventListener(prefixes.transitionEnd, setFocus);
      };
      this.$navigation.addEventListener(prefixes.transitionEnd, setFocus);

      // Adjust height
      this.adjustNavigationHeight();
    }
  }

  onBackLinkClick(event) {
    const $backLink = getTarget(
      event.target,
      '.header__navigation-submenu-item--back',
    );

    if ($backLink) {
      event.preventDefault();

      // Get elements
      const $submenu = $backLink.closest('.header__navigation-submenu');
      const $item = $submenu.closest(
        '.header__navigation-submenu-item, .header__navigation-item',
      );

      // Move 1 level up
      const navigationClasses = this.$navigation.className;
      const whichLevel = parseInt(
        navigationClasses.substr(navigationClasses.length - 1),
        10,
      );
      this.$navigation.className = `header__navigation-inner header__navigation--level-${whichLevel
        - 1}`;

      // Hide submenu
      $submenu.classList.remove('header__navigation-submenu--current');

      // Remove aria attribute
      const $moveLink = $item.querySelector('.header__navigation-move');
      $moveLink.setAttribute('aria-expanded', 'false');

      // Set focus to move link back
      if (!this.dontFocusBackToMoveLink) {
        unvisibleFocus($moveLink);
      }

      // Adjust height
      this.adjustNavigationHeight();

      // Reset
      this.dontFocusBackToMoveLink = false;
    }
  }

  onFocusin(event) {
    const $level1 = getTarget(event.target, '.header__navigation-link');
    const $level2 = getTarget(
      event.target,
      '.navigation__submenu--level-2 > .navigation__submenu-item > .navigation__submenu-link',
    );
    const $level3 = getTarget(
      event.target,
      '.navigation__submenu--level-3 > .navigation__submenu-item > .navigation__submenu-link',
    );
    const $winner = $level1 || $level2 || $level3 || false;

    if ($winner) {
      if (
        ($level1
          && this.$navigation.classList.contains('navigation--level-2'))
          || ($level2
          && this.$navigation.classList.contains('navigation--level-3'))
          || ($level3 && this.$navigation.classList.contains('navigation--level-4'))
      ) {
        // Find open submenu
        const allOpenSubmenu = Array.from(
          this.$navigation.querySelectorAll('.navigation__submenu--current'),
        );

        // Don't focus back to the back link
        this.dontFocusBackToMoveLink = true;

        // Close all
        if ($level1) {
          allOpenSubmenu.reverse().forEach(($submenu) => {
            const $backLink = $submenu.querySelector('.navigation__back');
            $backLink.click();
          });

          // Close last one
        } else {
          const $submenu = allOpenSubmenu[allOpenSubmenu.length - 1];
          const $backLink = $submenu.querySelector('.navigation__back');
          $backLink.click();
        }

        // Focus back
        unvisibleFocus($winner);
      }
    }
  }
}
