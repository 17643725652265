import RellaxScrolling from 'rellax';
import { BREAKPOINTS } from 'javascripts/constants';

const componentStore = [];

document.querySelectorAll('.image-wall').forEach(($wall) => {
  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.l})`);

  const onMediaQueryChange = () => {
    const isL = mql.matches;
    const $items = $wall.querySelectorAll('.image-wall__item');

    const speeds = {
      default: 0,
      slow: isL ? 2 : 1,
      fast: isL ? 5 : 3,
    };

    if ($items.length <= 1) {
      return;
    }

    if ($items.length === 2) {
      componentStore.push(new RellaxScrolling($items[1], {
        speed: speeds.fast,
        center: true,
      }));
    }

    if ($items && $items.length > 2) {
      $items.forEach(($item, i) => {
        let speed = speeds.default;

        if (isL) {
          // first, every 2n from second, every 7n
          if (i === 0 || ((i + 1) % 2 === 0 && i !== 1) || (i + 1) % 7 === 0) speed = speeds.fast;
          // every 4th
          if ((i + 1) % 4 === 0) speed = speeds.slow;
        } else {
          // first, every 4n from third
          if (i === 0 || ((i + 1) % 4 === 0)) speed = speeds.fast;
          // every 3n
          if ((i + 1) % 3 === 0) speed = speeds.slow;
        }

        componentStore.push(new RellaxScrolling($item, {
          speed,
          center: true,
        }));
      });
    }
  };
  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
