document.querySelectorAll('.list-item--tooltip').forEach((wrap) => {
  const tooltip = wrap.querySelector('.list-item__tooltip');
  const button = wrap.querySelector('.list-item__tooltip__button');
  const popup = wrap.querySelector('.list-item__tooltip__popup');
  const close = wrap.querySelector('.list-item__tooltip__popup__close');
  const visibleClass = 'list-item__tooltip--visible';

  // Open popup
  const openPopup = (event) => {
    popup.setAttribute('aria-expanded', tooltip.classList.contains(visibleClass));
    tooltip.classList.add(visibleClass);
    event.currentTarget.focus();
    event.stopPropagation();
    return false;
  };
  button.addEventListener('click', openPopup);
  button.addEventListener('mouseover', openPopup);

  // Close popup
  const closePopup = () => {
    popup.removeAttribute('aria-expanded', tooltip.classList.contains(visibleClass));
    tooltip.classList.remove(visibleClass);
    button.blur();
  };
  close.addEventListener('click', closePopup);
  wrap.addEventListener('mouseleave', closePopup);

  // Click outside
  const clickOutsidePopup = (event) => {
    const isClickInside = popup.contains(event.target);
    if (!isClickInside) {
      tooltip.classList.remove(visibleClass);
      button.blur();
    }
  };
  document.addEventListener('click', clickOutsidePopup);
});
