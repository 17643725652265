export default class TeaserFilterList {
  constructor($element, options = {}) {
    this.$element = $element;

    this.options = {
      classes: {
        filter: 'filter-checkbox-list',
        filterCheckbox: 'filter-checkbox',
        filterShowMoreButton: 'teaser-filter-list__button',
        gridItem: 'teaser-grid__item',
        teaserVisible: 'teaser--visible',
        teaserInvisible: 'teaser--invisible',
        allGridCards: 'teaser--filter[data-tags]',
      },
      ...options,
    };

    this.init();
  }

  init() {
    this.$filter = this.$element.querySelector(`.${this.options.classes.filter}`);
    this.$filter.addEventListener('reset', this.filterReset.bind(this));
    this.$filterCheckboxes = this.$element.querySelectorAll(`.${this.options.classes.filterCheckbox}`);
    this.$filterShowMoreButton = this.$element.querySelector(`.${this.options.classes.filterShowMoreButton}`);
    this.$filterShowMoreButton.addEventListener('click', this.showMoreCards.bind(this));
    this.$gridItems = this.$element.querySelectorAll(`.${this.options.classes.gridItem}`);
    this.$allGridCards = this.$element.querySelectorAll(`.${this.options.classes.allGridCards}`);

    // Find filter and apply/remove sticky class on scroll
    const teaserFilterList = document.querySelector('.teaser-filter-list__filter');
    const sticky = teaserFilterList.getBoundingClientRect().top + window.scrollY;

    function makeTeaserSticky() {
      if (window.scrollY > sticky) {
        teaserFilterList.classList.add('teaser-filter-list--sticky');
      } else {
        teaserFilterList.classList.remove('teaser-filter-list--sticky');
      }
    }

    // Handle teaser-filter-list sticky behavior on desktop
    // Add room for header, so both don't overlap
    function addHeaderRoomOnSticky() {
      const headroomPinned = document.querySelector('.headroom-header-pinned');
      const stickyTeaserFilterlist = document.querySelector('.teaser-filter-list--sticky');

      if (headroomPinned && stickyTeaserFilterlist) {
        teaserFilterList.classList.add('teaser-filter-list--sticky-headroom');
      } else if (!headroomPinned && stickyTeaserFilterlist) {
        teaserFilterList.classList.remove('teaser-filter-list--sticky-headroom');
      }

      return false;
    }

    window.onscroll = () => {
      makeTeaserSticky();
      addHeaderRoomOnSticky();
    };

    // Add modifier .teaser--visible to all card items onload
    const teasersOnload = 9;
    if (this.$filter) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.$gridItems.length; i++) {
        if (i < teasersOnload) {
          this.$gridItems[i].classList.add('teaser--visible');
        } else {
          this.$gridItems[i].classList.add('teaser--invisible');
        }
      }
    }

    this.countGridItems();
    this.initFilter();
  }

  isTeaserSticky() {
    const isTeaserSticky = document.querySelector('.teaser-filter-list--sticky');

    if (isTeaserSticky) {
      return true;
    }
    return false;
  }

  getClosestDataSector() {
    // Query all checked checkboxes
    const checkedFilterCheckboxes = this.$element.querySelectorAll('.filter-checkbox__checkbox-input:checked');
    // Get the closest aka the corresponding data-sector
    const closestCheckboxDataSector = Array.from(checkedFilterCheckboxes).map(filterInput => filterInput.closest('[data-sector]').dataset.sector.toLowerCase());

    return closestCheckboxDataSector;
  }

  countGridItems() {
    const closestCheckboxDataSector = this.getClosestDataSector();

    let filteredCards = null;
    if (closestCheckboxDataSector.length > 0) {
      filteredCards = this.getInvisibleCardsByTagName(closestCheckboxDataSector);
    } else {
      filteredCards = this.$element.querySelectorAll(`.${this.options.classes.teaserInvisible}`);
    }

    const actualTeaserAmount = filteredCards.length;

    if (actualTeaserAmount > 0) {
      this.$filterShowMoreButton.style.display = 'flex';
    } else {
      this.$filterShowMoreButton.style.display = 'none';
    }
  }

  initFilter() {
    // Attach filterCards() to each filter checkbox
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < this.$filterCheckboxes.length; i++) {
      const filterCheckbox = this.$filterCheckboxes[i];

      filterCheckbox.addEventListener('change', () => {
        const checkedInputId = filterCheckbox.querySelector('.filter-checkbox__checkbox-input').id;
        this.filterCards(checkedInputId);
      });
    }
  }

  getInvisibleCardsByTagName(tagName, limit = 9) {
    // Get all grid elements
    const allGridCards = this.$allGridCards;

    // Filter elements
    const filteredGridCards = Array.from(allGridCards).filter((aGridCard) => {
      // Parse the data-tag attribute as JSON
      const cardTags = JSON.parse(aGridCard.getAttribute('data-tags').toLowerCase());

      // Check if the element has the 'invisible' class and if one of the tags is in the array
      return aGridCard.closest('.teaser-grid__item').classList.contains('teaser--invisible') && (cardTags.includes(tagName[0].toLowerCase()));
    }).slice(0, limit);

    return filteredGridCards;
  }

  setAllCardsInvisible() {
    const allGridCards = this.$allGridCards;

    allGridCards.forEach((el) => {
      const card = el.closest('.teaser-grid__item');
      card.classList.remove('teaser--visible');
      card.classList.add('teaser--invisible');
    });
  }

  showMoreCards(event) {
    if (event) {
      event.preventDefault();
    }

    const closestCheckboxDataSector = this.getClosestDataSector();

    let filteredCards = null;
    if (closestCheckboxDataSector.length > 0) {
      // Filter was selected, get 'invisible' cards with that sector
      filteredCards = this.getInvisibleCardsByTagName(closestCheckboxDataSector);
    } else {
      // No filter was selected -> search for the next batch of 'invisible' cards
      filteredCards = Array.from(this.$element.querySelectorAll('.teaser-grid__item.teaser--invisible .teaser--filter[data-tags]')).slice(0, 9);
    }

    filteredCards.forEach((el) => {
      const card = el.closest('.teaser-grid__item');
      card.classList.add('teaser--visible');
      card.classList.remove('teaser--invisible');
    });

    this.countGridItems();
  }

  filterCards(checkedInputId) {
    // Uncheck all checkboxes, except the one selected after click
    if (this.$filter && checkedInputId !== undefined) {
      // Query all checked checkboxes
      const checkedFilterCheckboxes = this.$element.querySelectorAll('.filter-checkbox__checkbox-input:checked');

      Array.from(checkedFilterCheckboxes).forEach((filterInput) => {
        if (filterInput.id !== checkedInputId) {
          // eslint-disable-next-line no-param-reassign
          filterInput.checked = false;
        }
      });
    }

    // If no data-sector is returned (i.e. users uncheck all checkboxes manually)
    // reset the filter and exit the function
    const closestCheckboxDataSector = this.getClosestDataSector();
    if (!closestCheckboxDataSector.length) {
      this.filterReset();
      return;
    }

    // If checkboxes are sticky, scroll back to filter
    if (this.isTeaserSticky()) {
      this.$element.scrollIntoView();
    }

    this.setAllCardsInvisible();
    this.showMoreCards();
    this.countGridItems();
  }

  filterReset() {
    const allGridCards = this.$element.querySelectorAll('.teaser-grid__item');

    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < allGridCards.length; i++) {
      const card = allGridCards[i];

      if (i < 9) {
        card.classList.add('teaser--visible');
        card.classList.remove('teaser--invisible');
      } else {
        card.classList.remove('teaser--visible');
        card.classList.add('teaser--invisible');
      }
    }

    this.countGridItems();
  }
}

document.querySelectorAll('.js-teaser-filter-list').forEach(
  $filterList => new TeaserFilterList($filterList),
);
