/* eslint-disable func-names */

const setTourmapMarkers = (map, locations, iconUrl) => {
  const icon = { url: iconUrl };
  const markers = [];
  const infoss = [];
  for (let i = 0; i < locations.length; i += 1) {
    const location = locations[i];
    const myLatLng = new google.maps.LatLng(location[1], location[2]);

    markers[i] = new google.maps.Marker({
      position: myLatLng,
      icon,
      title: location[0],
      zIndex: location[3],
    });
    infoss[i] = new google.maps.InfoWindow({
      content: location[4],
    });
    markers[i].setMap(map);
    markers[i].addListener('click', function () {
      infoss[this.zIndex].open(map, this);
    });
  }
  window.tourmapMarkers = markers;
};

if (document.querySelector('.tourmap')) {
  const script = document.createElement('script');
  script.async = true;

  // Consentmanager Dynamic Content blocking
  script.dataset.cmpSrc = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAdm0QeekpgeMH8K1_gzFBF0z31RPoKD_I&callback=initMap';
  script.dataset.cmpVendor = 's1104';
  script.dataset.cmpPreviewImage = 'https://www.coaching4future.de/dest/images/c4f/tourmap-bg.jpg';
  script.classList.add('cmplazyload');

  // Attach your callback function to the `window` object
  window.initMap = function () {
    const centerOfMap = new google.maps.LatLng(49.5896744, 11.011961100000008);
    const mapsZoom = 7;
    const mapOptions = {
      zoom: mapsZoom,
      center: centerOfMap,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const mapElement = document.getElementById('tourmap');
    const iconUrl = mapElement.dataset.icon || '/images/c4f/pin.png';

    const map = new google.maps.Map(mapElement, mapOptions);
    setTourmapMarkers(map, window.tourmapMarkersArray, iconUrl);
  };

  // Append the 'script' element to the Maps Element
  document.querySelector('.tourmap__map').appendChild(script);
}
