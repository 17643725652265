import h from 'hyperscript';
import icon from 'components/_particles/icon/generate-icon';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';

// PhotoSwipe template
const $photoswipeTemplate = h('.pswp', {
  attrs: {
    tabindex: '-1',
    role: 'dialog',
    'aria-hidden': 'true',
  },
}, h('.pswp__bg'), h('.pswp__scroll-wrap',
  h('.pswp__container',
    h('.pswp__item'),
    h('.pswp__item'),
    h('.pswp__item')),
  h('.pswp__ui.pswp__ui--hidden',
    h('.pswp__top-bar',
      h('.pswp__counter'),
      h('button.pswp__button.pswp__button--close', { title: 'Schließen (Esc)' },
        icon({ icon: 'close' }),
        h('span.u-hidden-visually', 'Schließen (Esc)')),
      h('.pswp__preloader',
        h('.pswp__preloader__icn',
          h('.pswp__preloader__cut',
            h('.pswp__preloader__donut'))))),
    h('button.pswp__button.pswp__button--arrow--left', { title: 'Vorheriges Bild (Pfeil links)' },
      icon({ icon: 'chevron-left' }),
      h('span.u-hidden-visually', 'Vorheriges Bild')),
    h('button.pswp__button.pswp__button--arrow--right', { title: 'Nächstes Bild (Pfeil rechts)' },
      icon({ icon: 'chevron-right' }),
      h('span.u-hidden-visually', 'Nächstes Bild')),
    h('.pswp__caption',
      h('.pswp__caption__center')))));

// Create single gallery item
const createGalleryItem = ($thumb) => {
  // Get image size
  const $figure = $thumb.closest('.figure');
  const size = $thumb.getAttribute('data-size').split('x');

  // Check for caption/copyright
  let title;
  const $caption = $figure ? $figure.querySelector('.figure__caption') : null;
  const $copyright = $figure ? $figure.querySelector('.tooltip__text') : null;

  if ($caption && !$copyright) {
    title = `<span class='text'>${$caption.textContent}</span>`;
  } else if ($caption && $copyright) {
    title = `<span class='text'>${$caption.textContent}</span>`;
  }

  return {
    src: $thumb.getAttribute('href'),
    w: parseInt(size[0], 10),
    h: parseInt(size[1], 10),
    title,
  };
};

// Attach photoswipe to items
const attachPhotoswipe = $thumbs => $thumbs.forEach(
  ($thumb) => {
    $thumb.addEventListener('click', (event) => {
      event.preventDefault();

      // Insert template
      const $template = $photoswipeTemplate.cloneNode(true);
      document.body.appendChild($template);

      // Get gallery-items by data-gallery and create the galleryItems array accordingly
      let start = 0;
      const galleryItems = [];
      const { gallery } = $thumb.dataset;
      const thumbHref = $thumb.getAttribute('href');
      const $items = document.querySelectorAll(`[data-gallery=${gallery}]`);
      $items.forEach(($item, index) => {
        const itemHref = $item.getAttribute('href');
        if (itemHref === thumbHref) {
          start = index;
        }
        galleryItems.push(createGalleryItem($item));
      });

      // Init PhotoSwipe
      const slideshow = new PhotoSwipe(
        $template,
        PhotoSwipeUiDefault,
        galleryItems,
        {
          index: start,
          barsSize: { top: 80, bottom: 'auto' },
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          mouseUsed: true,
        },
      );

      // After gallery is closed, destroy DOM
      slideshow.listen('destroy', () => $template.remove());

      // Start gallery
      slideshow.init();
    });
  },
);

const initPhotoswipe = ($thumbs) => {
  if (!$thumbs || !$thumbs.length) {
    return;
  }

  attachPhotoswipe($thumbs);
};

export default initPhotoswipe;
