document.querySelectorAll('.countdown').forEach((wrap) => {
  const daysItem = wrap.querySelector('.countdown__days');
  const hoursItem = wrap.querySelector('.countdown__hours');
  const minItem = wrap.querySelector('.countdown__min');
  const secItem = wrap.querySelector('.countdown__sec');

  const countDown = () => {
    const futureDate = new Date(wrap.getAttribute('data-date'));
    const currentDate = new Date();
    const myDate = futureDate - currentDate;
    const days = Math.floor(myDate / 1000 / 60 / 60 / 24);
    const hours = Math.floor(myDate / 1000 / 60 / 60) % 24;
    const min = Math.floor(myDate / 1000 / 60) % 60;
    const sec = Math.floor(myDate / 1000) % 60;
    daysItem.innerHTML = days;
    hoursItem.innerHTML = hours;
    minItem.innerHTML = min;
    secItem.innerHTML = sec;
  };

  countDown();
  setInterval(countDown, 1000);
});
