import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class ToggleButton {
  constructor($toggleButton, config) {
    this.$toggleButton = $toggleButton;
    this.config = config;
    this.$panel = document.getElementById(
      this.$toggleButton.getAttribute('aria-controls'),
    );
    this.$closeButton = this.$panel.querySelector(this.config.closeButton);
    this.$header = this.$toggleButton.closest('.header');
    this.trigger = false;

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
    if (this.$closeButton) {
      this.$closeButton.addEventListener('click', this.bindedCloseClick);
    }

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      initialFocus: this.config ? this.config.initialFocus : null,
    });
  }

  toggle(event) {
    event.preventDefault();

    if (event.target.closest('.header__search-button')) {
      this.trigger = 'search';
    } else {
      this.trigger = 'burger';
    }

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  panelClick(event) {
    if (this.trigger === 'burger' && event.target.closest('.header__navigation-inner')) return;
    if (this.trigger === 'search' && event.target.closest('.header__search-form')) return;

    this.closePanel();
  }

  closePanel() {
    this.$toggleButton.setAttribute('aria-expanded', 'false');
    this.$panel.classList.remove('header__panel--open');
    if (this.$header) {
      this.$header.classList.remove('header--open-clicked');
    }

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    if (this.$closeButton) {
      this.$closeButton.removeEventListener('click', this.bindedCloseClick);
    }

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    this.$toggleButton.setAttribute('aria-expanded', 'true');
    this.$panel.classList.add('header__panel--open');

    if (this.$header) {
      this.$header.classList.add('header--open-clicked');
    }

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    if (this.$closeButton) {
      this.$closeButton.addEventListener('click', this.bindedCloseClick);
    }

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Enable focus trap
    this.focusTrap.activate();
  }
}
