export default class Video {
  constructor($el) {
    this.$el = $el;
    this.$button = $el.querySelector('.video__button');
    this.$video = $el.querySelector('video');
    this.$mediaSources = $el.querySelectorAll('.video__media');
    this.play = true;

    const isPlaying = this.isPlaying.bind(this);
    const isPaused = this.isPaused.bind(this);
    const togglePlay = this.togglePlay.bind(this);
    const detectMedia = this.detectMedia.bind(this);

    this.$el.addEventListener('play', isPlaying);
    this.$el.addEventListener('pause', isPaused);
    this.$button.addEventListener('click', togglePlay);

    if (this.$mediaSources.length > 0) {
      const mql = [];
      this.$mediaSources.forEach(($mediaSource) => {
        mql.push(
          window.matchMedia(
            $mediaSource.getAttribute('data-media') || '(min-width: 0px)',
          ),
        );
      });

      mql.forEach(q => q.addEventListener('change', detectMedia));
      this.detectMedia();
    }
  }

  isPlaying() {
    this.play = true;
  }

  isPaused() {
    this.play = false;
  }

  togglePlay() {
    if (this.play) {
      this.$video.pause();
    } else {
      this.$video.play();
    }
    this.play = !this.play;
    this.$button.classList.toggle('video__button--paused');
  }

  getBreakpointFromMediaquery(mediaquery) {
    const breakpointRegex = /\d+/;
    const breakpointMatch = mediaquery.match(breakpointRegex);
    const breakpoint = breakpointMatch
      ? parseInt(breakpointMatch[0], 10)
      : null;
    return breakpoint;
  }

  // gets best source to render based on media query
  getClosestMatch() {
    const sources = [];

    this.$mediaSources.forEach(($mediaSource) => {
      const mediaQuery = $mediaSource.getAttribute('data-media') || '(min-width: 0px)';
      const { matches } = window.matchMedia(mediaQuery);
      const breakpoint = this.getBreakpointFromMediaquery(mediaQuery);

      if (matches) {
        sources.push({
          breakpoint,
          content: $mediaSource.content.cloneNode(true),
          width: $mediaSource.getAttribute('data-width'),
          height: $mediaSource.getAttribute('data-height'),
        });
      }
    });

    let biggestBreakpoint = sources[0];
    sources.forEach((s) => {
      if (
        s.breakpoint < document.body.clientWidth && s.breakpoint > biggestBreakpoint.breakpoint
      ) {
        biggestBreakpoint = s;
      }
    });
    return biggestBreakpoint;
  }

  // detetcts which <source> to render
  detectMedia() {
    const $source = this.$video.querySelector('source');

    if (this.$mediaSources) {
      const closestMatch = this.getClosestMatch();

      if (closestMatch) {
        if (
          $source?.getAttribute('src') !== closestMatch.content.querySelector('source')?.getAttribute('src')
        ) {
          if ($source) {
            $source.remove();
          }

          const {
            width, height, content,
          } = closestMatch;


          if (width) {
            this.$video.setAttribute('data-width', width);
          }

          if (height) {
            this.$video.setAttribute('data-height', height);
          }

          if (width && height) {
            this.$el.setAttribute(
              'style',
              `padding-top: ${(height / width) * 100}%;`,
            );
          }
          this.$video.appendChild(content);
          this.$video.load();
        }
      }
    }
  }
}

document.querySelectorAll('.video').forEach($el => new Video($el));
