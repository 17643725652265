import throttle from 'javascripts/utils/throttle';
import h from 'hyperscript';
import iconButton from '../../atoms/icon-button/icon-button';

const changeTop = 200;

// Construct the to Top element
const $contentToTop = h('.page__to-top');
const $toTop = h('.to-top');
const $toTopButton = iconButton({
  link: '#top',
  icon: 'arrow-top',
  title: 'zurück nach oben',
  secondary: true,
});

$toTop.appendChild($toTopButton);
$contentToTop.appendChild($toTop);
document.getElementById('page').appendChild($contentToTop);

const toTop = () => {
  const scrollY = window.scrollY || window.pageYOffset;

  if (scrollY > changeTop) {
    $toTop.classList.add('to-top--visible');
  } else if (scrollY < changeTop) {
    $toTop.classList.remove('to-top--visible');
  }
};

document.addEventListener('scroll', throttle(toTop), { passive: true });
