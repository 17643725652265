export default class HeaderFlyout {
  constructor($navigation) {
    this.$navigation = $navigation;
    this.$navigationLi = this.$navigation.querySelectorAll(
      '.header__navigation-level-1-li',
    );
    this.$navigationLinks = this.$navigation.querySelectorAll(
      '.header__navigation-link, .header__navigation-level-2--teaser-style header__navigation-level-2-li a',
    );
    this.$navigationSecondLevels = this.$navigation.querySelectorAll(
      '.header__navigation-level-2-ul',
    );

    this.timer = null;
  }

  enter = (event) => {
    const $target = event.currentTarget;
    if ($target) {
      const $submenu = event.currentTarget.querySelector('.header__navigation-submenu');
      if ($submenu) {
        this.timer = setTimeout(() => {
          $submenu.classList.add('header__navigation-submenu--open');
          $target.classList.add('header__navigation-item--active');
        }, 500);
      }
    }
  }

  leave = (event) => {
    clearTimeout(this.timer);
    const $target = event.currentTarget;
    this.$navigation
      .querySelectorAll('.header__navigation-submenu--open')
      .forEach(($sub) => {
        $sub.classList.remove('header__navigation-submenu--open');

        if ($target) {
          $target.classList.remove('header__navigation-item--active');
        }
      });
  };

  focus = (event) => {
    const $target = event.currentTarget;

    if ($target) {
      const $submenu = $target
        .closest('.header__navigation-level-1-li')
        .querySelector('.header__navigation-submenu');
      this.$navigation
        .querySelectorAll('.header__navigation-submenu--open')
        .forEach(($sub) => {
          $sub.classList.remove('header__navigation-submenu--open');
        });

      if ($submenu) {
        $submenu.classList.add('header__navigation-submenu--open');
      }
    }
  }

  initLayout() {
    this.$navigationSecondLevels.forEach(($level) => {
      const itemCount = $level.querySelectorAll('.header__navigation-level-2-li:not(.header__navigation-teaser-li)').length;
      const columnCount = ((num) => {
        switch (num) {
          case 4: case 7: case 8:
            return 4;
          default:
            return 3;
        }
      })(itemCount);
      $level.classList.add(`header__navigation-level-2--layout-c${columnCount}`);
    });
  }

  init() {
    this.$navigation.closest('.header').classList.add('header--nav-init');

    this.$navigationLi.forEach(($li) => {
      $li.addEventListener('mouseenter', this.enter);
      $li.addEventListener('mouseleave', this.leave);
    });

    this.$navigationLinks.forEach(($link) => {
      $link.addEventListener('focus', this.focus);
    });

    this.initLayout();
  }

  deinit() {
    this.$navigation.closest('.header').classList.remove('header--nav-init');

    this.$navigationLi.forEach(($li) => {
      $li.removeEventListener('mouseenter', this.enter);
      $li.removeEventListener('mouseleave', this.leave);
    });

    this.$navigationLinks.forEach(($link) => {
      $link.removeEventListener('focus', this.focus);
    });
  }
}
