/* eslint-disable arrow-parens */
document.querySelectorAll('.glossary__collapse').forEach($collapse => {
  const handleClick = () => {
    const controls = $collapse?.getAttribute('aria-controls');
    if (controls) {
      const $hidden = document.getElementById(controls);
      $hidden?.setAttribute(
        'aria-expanded',
        $hidden.getAttribute('aria-expanded') === 'true' ? 'false' : 'true',
      );
    }
  };

  $collapse.addEventListener('click', handleClick);
});
