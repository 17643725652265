import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.overview-text-image').forEach(($el) => {
  // Init media query
  const mql = [
    window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
    window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`),
    window.matchMedia(`(min-width: ${BREAKPOINTS.xxl})`),
  ];

  // Listener
  const onMediaQueryChange = () => {
    const isL = mql[0].matches;

    if (isL) {
      const $image = $el.querySelector('.overview-text-image__image');
      const minHeight = $image.offsetHeight + 150;
      $el.querySelector('.overview-text-image__inner').style.minHeight = `${minHeight}px`;
    } else {
      $el.querySelector('.overview-text-image__inner').removeAttribute('style');
    }
  };

  // Listen on media query changes
  mql.forEach(((el) => {
    el.addListener(onMediaQueryChange);
  }));

  // First run
  onMediaQueryChange(mql);
});
