import Choices from 'choices.js';

document.querySelectorAll('.select-multiple__select').forEach((element) => {
  const choices = new Choices(element, {
    removeItems: true,
    removeItemButton: true,
    allowHTML: false,
    searchResultLimit: 20,
    itemSelectText: '',
    position: 'bottom',
    noResultsText: 'Keine Ergebnisse gefunden',
    noChoicesText: 'Keine Optionen zur Auswahl',
    classNames: {
      containerOuter: 'select-multiple__choices',
      containerInner: 'select-multiple__choices__inner',
      input: 'select-multiple__choices__input',
      inputCloned: 'select-multiple__choices__input--cloned',
      list: 'select-multiple__choices__list',
      listItems: 'select-multiple__choices__list--multiple',
      listSingle: 'select-multiple__choices__list--single',
      listDropdown: 'select-multiple__choices__list--dropdown',
      item: 'select-multiple__choices__item',
      itemSelectable: 'select-multiple__choices__item--selectable',
      itemDisabled: 'select-multiple__choices__item--disabled',
      itemChoice: 'select-multiple__choices__item--choice',
      placeholder: 'select-multiple__choices__placeholder',
      group: 'select-multiple__choices__group',
      groupHeading: 'select-multiple__choices__heading',
      button: 'select-multiple__choices__button',
    },
    callbackOnInit: () => {
      const search = element.parentNode.querySelector('input');

      if (element.options.length > 0) {
        search.style.opacity = 0;
      } else {
        search.style.opacity = 1;
      }
    },
  });
  choices.passedElement.element.addEventListener(
    'removeItem',
    (event) => {
      const search = event.target.parentNode.querySelector('input');
      if (event.target.options.length > 0) {
        search.style.opacity = 0;
      } else {
        search.style.opacity = 1;
      }
    },
    false,
  );

  choices.passedElement.element.addEventListener(
    'addItem',
    (event) => {
      const search = event.target.parentNode.querySelector('input');
      if (event.target.options.length > 0) {
        search.style.opacity = 0;
      } else {
        search.style.opacity = 1;
      }
    },
    false,
  );
});
