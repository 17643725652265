import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let overlayFiftyFifty;

export default class OverlayFiftyFifty {
  constructor($overlay, $index) {
    this.$button = $overlay;
    this.$index = $index;
    this.$button.setAttribute('data-overlay-id', $index);
    this.$panel = document.getElementById(this.$button.getAttribute('aria-controls'));
    this.$panel.setAttribute('id', `overlay-${$index}`);
    this.$teaserButtons = document.querySelectorAll('.overlay-fifty-fifty__button');
    this.$panelContent = this.$panel.querySelector('.overlay-fifty-fifty__content');
    this.$closeButton = this.$panel.querySelector('.overlay-fifty-fifty__close');
    this.$sliderButtonLeft = document.querySelector('.overlay-fifty-fifty__slider-button-left');
    this.$sliderButtonRight = document.querySelector('.overlay-fifty-fifty__slider-button-right');
    this.$closestTeaserContent = this.$button.closest('.teaser__content');

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
    this.bindedSlideToLeft = this.slideToLeft.bind(this);
    this.bindedSlideToRight = this.slideToRight.bind(this);

    // this.$button.addEventListener('click', this.bindedClick);
    this.$closestTeaserContent.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);
    this.$sliderButtonLeft.addEventListener('click', this.bindedSlideToLeft);
    this.$sliderButtonRight.addEventListener('click', this.bindedSlideToRight);

    this.DOMrepositionOverlay();
    this.initSwipe();

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });
  }

  /**
   *
   * @returns {string[]}
   */
  countGridElements() {
    const realAmountGridElements = overlayFiftyFifty;
    const visibleGridElements = document.querySelectorAll('.teaser--visible');
    const filterOn = document.querySelectorAll('.filter-checkbox__checkbox-input:checked');
    const overlayArray = [];

    // Check if the user has filtered
    if (filterOn.length > 0) {
      // Count visible elements
      visibleGridElements.forEach(element => overlayArray.push(element.querySelector('.overlay-fifty-fifty__button').dataset.overlayId));
    } else {
      // Count all rendered elements
      realAmountGridElements.forEach(element => overlayArray.push(element.getAttribute('data-overlay-id')));
    }
    return overlayArray;
  }

  /**
   * This function check if the current open Overlay is the first or the last one
   */
  returnOverlayPosition() {
    const overlayItemsArray = this.countGridElements();
    const currentOverlay = document.querySelector('.overlay-fifty-fifty__panel--open').id;
    const currentOverlayId = currentOverlay.replace('overlay-', '');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < overlayItemsArray.length; i++) {
      if (overlayItemsArray[i] === currentOverlayId) {
        if (i === 0) {
          return 'begin';
        // eslint-disable-next-line no-else-return
        } else if (i === overlayItemsArray.length - 1) {
          return 'end';
        }
        return 'between';
      }
    }

    return '';
  }

  slideToRight() {
    const overlayItemsArray = this.countGridElements();
    const currentOverlay = document.querySelector('.overlay-fifty-fifty__panel--open').id;
    const currentOverlayId = currentOverlay.replace('overlay-', '');
    const currentOverlayPosition = overlayItemsArray.indexOf(currentOverlayId);
    let nextOverlayId = '';

    if (currentOverlayPosition !== -1 && currentOverlayPosition < overlayItemsArray.length - 1) {
      nextOverlayId = overlayItemsArray[currentOverlayPosition + 1];
    }

    if (this.returnOverlayPosition() !== 'end') {
      this.closePanel();
      this.openPanel(nextOverlayId);
    }
  }

  slideToLeft() {
    const overlayItemsArray = this.countGridElements();
    const currentOverlay = document.querySelector('.overlay-fifty-fifty__panel--open').id;
    const currentOverlayId = currentOverlay.replace('overlay-', '');
    const currentOverlayPosition = overlayItemsArray.indexOf(currentOverlayId);
    let previousOverlayId = '';

    if (currentOverlayPosition !== -1 && currentOverlayPosition > 0) {
      previousOverlayId = overlayItemsArray[currentOverlayPosition - 1];
    }

    if (this.returnOverlayPosition() !== 'begin') {
      this.closePanel();
      this.openPanel(previousOverlayId);
    }
  }

  DOMrepositionOverlay() {
    // Overlay as articles are generated and mirroring the teaser elements
    document.body.appendChild(this.$panel);
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$button.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      // this.openPanel(this.$button.id);
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }

    // Use arrow left to slide
    if (event.keyCode === 37) {
      event.preventDefault();
      this.slideToLeft();
    }

    // Use arrow right to slide
    if (event.keyCode === 39) {
      event.preventDefault();
      this.slideToRight();
    }
  }

  initSwipe() {
    let touchstartX = 0;
    let touchendX = 0;
    const threshold = 100;

    this.$panel.addEventListener('touchstart', (event) => {
      touchstartX = event.changedTouches[0].screenX;
    });

    this.$panel.addEventListener('touchend', (event) => {
      touchendX = event.changedTouches[0].screenX;

      const difference = touchendX - touchstartX;
      const shouldSwipe = Math.abs(difference) >= threshold;

      if (shouldSwipe) {
        if (difference > 0) {
          this.slideToLeft();
        } else if (difference < 0) {
          this.slideToRight();
        }
      }
    });
  }

  panelClick(event) {
    if (event.target.closest('.overlay-fifty-fifty__content')) return;
    this.closePanel();
  }

  closePanel(event) {
    if (event) {
      event.preventDefault();
    }

    this.$teaserButtons.forEach((teaserButton) => {
      teaserButton.setAttribute('aria-expanded', 'false');
    });

    const panels = document.querySelectorAll('.overlay-fifty-fifty__panel');

    panels.forEach((panel) => {
      panel.classList.remove('overlay-fifty-fifty__panel--open');
    });

    // Remove keydown from body
    window.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    // Commented out, since removing this event creates a bug, where panels can't be closed
    // this.$closeButton.removeEventListener('click', this.bindedCloseClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
    clearAllBodyScrollLocks();
  }

  disableSliderButtons(sliderButtonLeft, sliderButtonRight) {
    if (this.returnOverlayPosition() === 'begin') {
      sliderButtonLeft.setAttribute('disabled', 'disabled');
    } else if (this.returnOverlayPosition() === 'end') {
      sliderButtonRight.setAttribute('disabled', 'disabled');
    } else {
      sliderButtonLeft.removeAttribute('disabled', 'disabled');
      sliderButtonRight.removeAttribute('disabled', 'disabled');
    }
  }

  getCheckedFilterCheckboxes() {
    // Query all checked checkboxes
    const checkedFilterCheckboxes = document.querySelectorAll('.filter-checkbox__checkbox-input:checked');
    // Get the closest aka the corresponding data-filterid
    const closestCheckboxDataSector = Array.from(checkedFilterCheckboxes).map(filterInput => filterInput.closest('[data-filterid]').dataset.filterid.toLowerCase());

    return closestCheckboxDataSector;
  }

  openPanel(id) {
    const checkedFilterCheckboxes = this.getCheckedFilterCheckboxes();

    if (typeof id === 'string') {
      // Get elements with corresponding id
      const panels = document.querySelectorAll('.overlay-fifty-fifty__panel');

      panels.forEach((panel) => {
        if (panel.id === `overlay-${id}`) {
          // Add selected filter as query param to cta-link
          if (checkedFilterCheckboxes[0] !== undefined) {
            const ctaLink = panel.querySelector('.cta-link');
            const url = new URL(ctaLink.href);

            // Set new href parameter for BE
            url.searchParams.set('filterId', checkedFilterCheckboxes[0]);
            // Set URL back into href-attribute
            ctaLink.href = url.href;
          }

          // Change toggle button
          this.$teaserButtons.forEach((teaserButton) => {
            if (teaserButton.id === panel.id) {
              teaserButton.setAttribute('aria-expanded', 'true');
            }
          });

          // Show panel
          panel.classList.add('overlay-fifty-fifty__panel--open');

          // Add keydown to body
          window.addEventListener('keydown', this.onKeydownBinded);
          panel.addEventListener('click', this.bindedPanelClick);
          const closeButton = panel.querySelector('.overlay-fifty-fifty__close');
          closeButton.addEventListener('click', this.bindedCloseClick);

          // Disable scrolling
          disableBodyScroll(panel);

          // Enable focus trap
          this.focusTrap.activate();

          // Check panel position and disable slider buttons if necessary
          const sliderButtonLeft = panel.querySelector('.overlay-fifty-fifty__slider-button-left');
          const sliderButtonRight = panel.querySelector('.overlay-fifty-fifty__slider-button-right');
          this.disableSliderButtons(sliderButtonLeft, sliderButtonRight);
        }
      });
    } else {
      // Add selected filter as query param to cta-link
      if (checkedFilterCheckboxes[0] !== undefined) {
        const ctaLink = this.$panel.querySelector('.cta-link');
        const url = new URL(ctaLink.href);

        // Set new href parameter for BE
        url.searchParams.set('filterId', checkedFilterCheckboxes[0]);
        // Set URL back into href-attribute
        ctaLink.href = url.href;
      }

      // Change toggle button
      this.$button.setAttribute('aria-expanded', 'true');

      // Show panel
      this.$panel.classList.add('overlay-fifty-fifty__panel--open');

      // Add keydown to body
      window.addEventListener('keydown', this.onKeydownBinded);
      this.$panel.addEventListener('click', this.bindedPanelClick);
      this.$closeButton.addEventListener('click', this.bindedCloseClick);

      // Disable scrolling
      disableBodyScroll(this.$panel);

      // Enable focus trap
      this.focusTrap.activate();

      // Check panel position and disable slider buttons if necessary
      const sliderButtonLeft = this.$panel.querySelector('.overlay-fifty-fifty__slider-button-left');
      const sliderButtonRight = this.$panel.querySelector('.overlay-fifty-fifty__slider-button-right');
      this.disableSliderButtons(sliderButtonLeft, sliderButtonRight);
    }
  }
}

// eslint-disable-next-line prefer-const
overlayFiftyFifty = document.querySelectorAll('.js-overlay-fifty-fifty.teaser__content-headline.overlay-fifty-fifty__button');
overlayFiftyFifty.forEach(
  ($element, $index) => new OverlayFiftyFifty($element, $index),
);
