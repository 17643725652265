/* eslint-disable prefer-destructuring */

export default class FormGroup {
  constructor($formGroup) {
    this.$formGroup = $formGroup;
    this.$input = this.$formGroup.querySelector('.input');

    if (this.$input) {
      this.inputType = this.$input.type;

      // For now we only apply to text and email types
      // but this can be extended when needed
      if (
        this.inputType === 'text'
        || this.inputType === 'email'
        || this.inputType === 'textarea'
        || this.inputType === 'password'
        || this.inputType === 'tel'
      ) {
        this.initialize();
      }
    }
  }

  initialize() {
    const value = this.$input.value;
    const placeholder = this.$input.placeholder;

    this.$formGroup.classList.add('form-group--textfield');

    if (this.inputType === 'textarea') {
      this.$formGroup.classList.add('form-group--textarea');
    }

    if (!value && !placeholder) {
      this.$formGroup.classList.add('form-group--textfield-empty');
    }

    this.$input.addEventListener('focusin', this.onFocusIn.bind(this));
    this.$input.addEventListener('change', this.onChange.bind(this));
    this.$input.addEventListener('focusout', this.onFocusOut.bind(this));
  }

  onFocusIn(event) {
    const { value, placeholder } = event.target;

    if (!value && !placeholder) {
      this.$formGroup.classList.remove('form-group--textfield-empty');
    }
  }

  onChange(event) {
    const { value, classList } = event.target;

    if (value && !classList.contains('form-group--textfield-empty')) {
      this.$formGroup.classList.remove('form-group--textfield-empty');
    }
  }

  onFocusOut(event) {
    const value = event.target.value;
    const placeholder = event.target.placeholder;

    if (!value && !placeholder) {
      this.$formGroup.classList.add('form-group--textfield-empty');
    }
  }
}

document.querySelectorAll('.form-group').forEach($formGroup => new FormGroup($formGroup));

// Toggle fieldset after checkbox
document.querySelectorAll('.form__fields > fieldset.form-group').forEach((fieldset) => {
  const checkboxBeforeFieldset = fieldset.previousElementSibling.querySelector('.checkbox__input');
  if (checkboxBeforeFieldset) {
    fieldset.classList.add('form-group--fieldset', 'form-group--hidden');
    const toggle = () => {
      fieldset.classList.toggle('form-group--hidden');
    };
    checkboxBeforeFieldset.addEventListener('change', toggle);
  }
});
