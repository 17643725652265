import randomId from 'javascripts/utils/random-id';

export default function generateIcon({
  icon,
  title,
  desc,
  classes = [],
  id,
  paths,
  viewBox = '0 0 200 200',
}) {
  const labelledby = [];
  const xmlns = 'http://www.w3.org/2000/svg';

  // Get ID for icon
  const newId = id || randomId();

  // Append classes
  classes.push('icon', `icon--${icon}`);

  // Generate icon
  const $icon = document.createElementNS(xmlns, 'svg');
  $icon.setAttributeNS(null, 'viewBox', viewBox);
  $icon.setAttributeNS(null, 'role', title && 'img');
  $icon.setAttributeNS(null, 'class', classes.join(' '));
  $icon.setAttributeNS(null, 'id', `icon-${newId}`);

  // Add title
  if (title) {
    labelledby.push(`title-${newId}`);

    const $title = document.createElementNS(xmlns, 'title');
    $title.setAttributeNS(null, 'id', `title-${newId}`);
    $title.appendChild(document.createTextNode(title));
    $icon.appendChild($title);
  }

  // Description
  if (desc) {
    labelledby.push(`desc-${newId}`);

    const $desc = document.createElementNS(xmlns, 'desc');
    $desc.setAttributeNS(null, 'id', `desc-${newId}`);
    $desc.appendChild(document.createTextNode(desc));
    $icon.appendChild($desc);
  }

  // Add aria-labelledby to icon
  if (labelledby.length > 0) {
    $icon.setAttributeNS(null, 'aria-labelledby', labelledby.join(' '));
  }

  // Add reference to icons
  if (!paths) {
    const $use = document.createElementNS(xmlns, 'use');

    $use.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `#icon-${icon}`,
    );

    $icon.appendChild($use);
    $icon.appendChild(document.createComment(' '));
  } else if (Array.isArray(paths)) {
    paths.forEach((path) => {
      const $path = document.createElementNS(xmlns, 'path');
      $path.setAttributeNS('', 'd', path);
      $icon.appendChild($path);
    });
  }

  return $icon;
}
