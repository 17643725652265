import { CountUp } from 'countup.js';

const $itemValueEls = document.querySelectorAll('.facts__item-value');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      const $target = entry.target;

      // The count-up animation
      const value = $target.textContent;
      const options = {
        decimalPlaces: $target.dataset.decimalplaces,
        decimal: $target.dataset.decimal,
        duration: $target.dataset.duration,
        useGrouping: false,
      };

      const countUp = new CountUp($target, value, options);
      countUp.start();

      observer.unobserve($target);
    }
  });
});

$itemValueEls.forEach(($itemValueEl) => {
  observer.observe($itemValueEl);
});
