/* eslint-disable prefer-destructuring */

export default class FormSearch {
  constructor($formSearch) {
    this.$formSearch = $formSearch;
    this.$input = this.$formSearch.querySelector('.input');
    this.$resetButton = this.$formSearch.querySelector('.form-search__reset');

    this.initialize();
  }

  initialize() {
    const value = this.$input.value;

    this.$input.addEventListener('keyup', this.toggleReset.bind(this));
    this.$input.addEventListener('focusin', this.toggleReset.bind(this));
    this.$input.addEventListener('focusout', this.toggleReset.bind(this));
    this.$resetButton.addEventListener('click', () => {
      this.$formSearch.classList.remove('form-search--value');
    });

    this.resetButton(value);
  }

  toggleReset(event) {
    const value = event.target.value;
    this.resetButton(value);
  }

  resetButton(value) {
    if (value) {
      this.$formSearch.classList.add('form-search--value');
    } else {
      this.$formSearch.classList.remove('form-search--value');
    }
  }
}

document.querySelectorAll('.form-search').forEach($formSearch => new FormSearch($formSearch));
