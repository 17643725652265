import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.content-carousel').forEach(($carousel) => {
  // Init media query
  const mql = [
    window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
    window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`),
    window.matchMedia(`(min-width: ${BREAKPOINTS.xxl})`),
  ];

  // Listener
  const onMediaQueryChange = () => {
    const isL = mql[0].matches;
    const $el = $carousel;

    if (isL) {
      const $slider = $carousel.querySelector('.content-carousel__slider');
      let minHeight = $slider.offsetHeight + 150;
      if ($carousel.querySelector('.content-carousel__icon')) {
        const iconHeight = $carousel.querySelector('.content-carousel__icon').offsetHeight + 30;
        minHeight += iconHeight;
      }
      $el.querySelector('.content-carousel__inner').style.minHeight = `${minHeight}px`;
    } else {
      $el.querySelector('.content-carousel__inner').removeAttribute('style');
    }
  };

  // Listen on media query changes
  mql.forEach(((el) => {
    el.addListener(onMediaQueryChange);
  }));

  // First run
  onMediaQueryChange(mql);
});
