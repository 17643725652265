import SliderNumber from 'javascripts/globals/card-slider';

const mql = [
  window.matchMedia('(min-width: 1203px)'),
  window.matchMedia('(min-width: 1024px)'),
  window.matchMedia('(min-width: 768px)'),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isL = mql[1].matches;
  const isM = mql[2].matches;
  const isS = mql[0].matches;

  let slidesToScroll = 1;

  if (isL) {
    slidesToScroll = 3;
  } else if (isM) {
    slidesToScroll = 1;
  } else if (isS) {
    slidesToScroll = 1;
  }

  document
    .querySelectorAll('.mission-card-wrapper')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.mission-card-wrapper__item');

      if ($items && ($items.length <= 3) && isL) {
        return;
      }

      componentStore.push(new SliderNumber($slider, {
        sliderFrame: 'mission-card-wrapper__frame',
        slidesContainer: 'mission-card-wrapper__items',
        sliderSlide: 'mission-card-wrapper__item',
        sliderControlsframe: 'mission-card-wrapper__controls',
        sliderControlsframemethod: 'append',
        sliderDotsframe: 'mission-card-wrapper__dots',
        sliderDotsframemethod: 'append',
        slidesToScroll,
        slidesFocusable: false,
      }));
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

onMediaQueryChange();
