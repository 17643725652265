import { lory } from '@rsm/allfarblori';
import generateIcon from 'components/_particles/icon/generate-icon';

export default class Slider {
  constructor($el, options) {
    this.$el = $el;

    // Classes have to be assigned to slider elements
    if (!options) {
      return;
    }

    this.options = {
      sliderControlsframemethod: 'append',
      infinite: false,
      rewind: false,
      slidesToScroll: 1,
      enableMouseEvents: true,
      slidesFocusable: false,
      autoplay: false,
      autoplayTime: 6000,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      rewindSpeed: 200,
      slideSpeed: 350,
      ...options,
    };

    // Slides
    this.$slides = this.$el.querySelectorAll(`.${this.options.sliderSlide}`);

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.slidesTotal = this.$slides.length;

    if (this.options.slidesToScroll > 1) {
      this.slidesTotal = Math.ceil(this.$slides.length / this.options.slidesToScroll);
    }

    this.initSlider();
  }

  initClasses() {
    // Frame
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);
    // Container: Collection of slides
    this.$slidesContainer = this.$el.querySelector(`.${this.options.slidesContainer}`);
    // Controls-Frame: Where to dock the controls
    this.$controlsFrame = this.$el.querySelector(`.${this.options.sliderControlsframe}`);
    this.controlsFrameMethod = this.options.sliderControlsframemethod;

    this.$sliderFrame.classList.add('slider__frame');
    this.$slidesContainer.classList.add('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.setAttribute('tabindex', '0');
        }
        $item.classList.add('slider__slide');
      });
    }
  }

  initSlider() {
    this.initClasses();

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');

      if (this.options.autoplay) {
        this.autoplayInvervall = setInterval(() => {
          this.lory.next();
        }, this.options.autoplayTime);

        this.$el.querySelectorAll('.slider__control')?.forEach($control => $control.addEventListener('click', () => {
          clearInterval(this.autoplayInvervall);
        }));
      }
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateControls();
    });

    this.lory = lory(this.$el, {
      slidesToScroll: this.options.slidesToScroll,
      infinite: this.options.infinite,
      rewind: this.options.rewind,
      rewindPrev: false,
      enableMouseEvents: true,
      rewindSpeed: this.options.rewindSpeed,
      slideSpeed: this.options.slideSpeed,
      ease: this.options.ease,
      classNameFrame: this.options.sliderFrame,
      classNameSlideContainer: this.options.slidesContainer,
      classNamePrevCtrl: 'slider__control--prev',
      classNameNextCtrl: 'slider__control--next',
    });

    // Focus in creates problems /w Quickinfo, thus commented out
    // if (this.$slidesContainer) {
    //   this.$slidesContainer.addEventListener('focusin', this.focusIn);
    // }
    window.addEventListener('resize', this.onResize);
    this.lory.reset();
  }

  initControls() {
    const $controlPrev = generateIcon({
      icon: 'nav-left',
      title: 'Vorherige',
      medium: true,
      classes: ['slider__control', 'slider__control--prev'],
    });

    const $controlNext = generateIcon({
      icon: 'nav-right',
      title: 'Nächste',
      medium: true,
      classes: ['slider__control', 'slider__control--next'],
    });

    this.$controlsFrame.innerHTML = '';
    this.$controlsFrame.appendChild($controlPrev);
    this.$controlsFrame.appendChild($controlNext);
  }

  updateControls() {
    const currentIndex = this.lory.returnIndex() + 1;
    const shownIndex = Math.ceil(currentIndex / this.options.slidesToScroll);
    if (this.$el.querySelector('.slider__current')) {
      this.$el.querySelector('.slider__current').innerHTML = shownIndex.toString().padStart(2, '0');
    }
  }

  onResize = () => {
    this.updateControls();
  }

  deconstructor() {
    const controls = this.$el.querySelectorAll('.slider__control');
    controls.forEach(($el) => {
      $el.remove();
    });
    this.$el.classList.remove('slider--initialized');
    this.$sliderFrame.classList.remove('slider__frame');
    this.$slidesContainer.classList.remove('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.removeAttribute('tabindex', null);
        }
        $item.classList.remove('slider__slide');
      });
    }

    if (this.lory) {
      this.lory.destroy();
    }
  }

  // Focus in creates problems /w Quickinfo, thus commented out
  // focusIn = (event) => {
  //   if (event.target) {
  //     const className = this.$slides[0].classList[0];
  //     this.$sliderFrame.scrollLeft = 0;
  //     const $slide = event.target.closest(`.${className}`);
  //     const index = Array.prototype.indexOf.call(this.$slides, $slide);
  //     this.lory.slideTo(index);
  //   }
  // }
}
