import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';


export default class SliderNumber {
  constructor($el, options) {
    this.$el = $el;

    if (!options) {
      return;
    }

    this.options = {
      sliderControlsframemethod: 'append',
      rewind: true,
      slidesToScroll: 1,
      slidesFocusable: true,
      ...options,
    };

    // Slides
    this.$slides = this.$el.querySelectorAll(`.${this.options.sliderSlide}`);

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.slidesTotal = this.$slides.length;


    if (this.options.slidesToScroll > 1) {
      this.slidesTotal = Math.ceil(this.$slides.length / this.options.slidesToScroll);
    }

    this.initSlider();
  }

  initClasses() {
    // Frame
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);
    // Container: Collection of slides
    this.$slidesContainer = this.$el.querySelector(`.${this.options.slidesContainer}`);
    // Controls-Frame: Where to dock the controls
    this.$controlsFrame = this.$el.querySelector(`.${this.options.sliderControlsframe}`);
    this.controlsFrameMethod = this.options.sliderControlsframemethod;
    this.$dotsFrame = this.$el.querySelector(`.${this.options.sliderDotsframe}`);

    this.$sliderFrame.classList.add('slider__frame');
    this.$slidesContainer.classList.add('slider__container');
    this.$dotsListItem = document.createElement('li');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.setAttribute('tabindex', '0');
        }
        $item.classList.add('slider__slide');
      });
    }
  }

  initSlider() {
    this.initClasses();

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      // this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateControls();
      this.swipeDetect();
    });

    this.lory = lory(this.$el, {
      slidesToScroll: this.options.slidesToScroll,
      rewind: this.options.rewind,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: this.options.sliderFrame,
      classNameSlideContainer: this.options.slidesContainer,
    });

    if (this.$slidesContainer) {
      this.$slidesContainer.addEventListener('focusin', this.focusIn);
    }
    window.addEventListener('resize', this.onResize);
    this.lory.reset();
    this.initDots();
  }

  swipeDetect() {
    const mql = [
      window.matchMedia('(min-width: 768px)'),
      window.matchMedia('(min-width: 1024px)'),
      window.matchMedia('(max-width: 767px)'),
      window.matchMedia('(min-width: 768px) and (max-width: 1023px)'),
    ];

    const isL = mql[1].matches;
    const isM = mql[0].matches;
    const isS = mql[2].matches;
    const isXL = mql[3].matches;

    const dotsDivide = this.$slides.length;
    let count;
    if (isL) {
      count = dotsDivide / 3;
    } else if (isM) {
      count = dotsDivide;
    } else {
      count = dotsDivide;
    }
    const maxThumb = Math.round(count);
    if (isS) {
      for (let i = 0; i < maxThumb; i += 1) {
        this.$dotsFrame.childNodes[i].classList.remove('active');
      }
      this.$dotsFrame.childNodes[this.lory.returnIndex()].classList.add('active');
    } else if (isXL) {
      for (let i = 0; i < maxThumb; i += 1) {
        this.$dotsFrame.childNodes[i].classList.remove('active');
      }
      if (maxThumb <= this.lory.returnIndex()) {
        this.$dotsFrame.childNodes[1].classList.add('active');
      } else {
        this.$dotsFrame.childNodes[this.lory.returnIndex()].classList.add('active');
      }
    }
  }

  initDots() {
    const mql = [
      window.matchMedia('(min-width: 768px)'),
      window.matchMedia('(min-width: 1024px)'),
      window.matchMedia('(min-width: 768px) and (max-width: 1023px)'),
    ];

    const isL = mql[1].matches;
    const isM = mql[0].matches;

    const dotsDivide = this.$slides.length;
    let count;
    if (isL) {
      count = dotsDivide / 3;
    } else if (isM) {
      count = dotsDivide;
    } else {
      count = dotsDivide;
    }
    this.$el.querySelector('.mission-card-wrapper__dots').innerHTML = '';
    const maxThumb = Math.round(count);
    const totalItems = this.$slides.length;
    for (let i = 0; i < maxThumb; i += 1) {
      if (this.$slides[i]) {
        const $thumb = h(
          'li', {
            onclick: (e) => {
              if (i < totalItems) {
                for (let j = 0; j < maxThumb; j += 1) {
                  this.$dotsFrame.childNodes[j].classList.remove('active');
                }
                this.lory.slideTo(i);
                this.$dotsFrame.childNodes[i].classList.add('active');
              }
              e.preventDefault();
            },
          },
        );
        this.$el.querySelector('.mission-card-wrapper__dots').appendChild($thumb);
      }
    }
    this.$dotsFrame.childNodes[0].classList.add('active');
  }

  updateControls() {
    const currentIndex = this.lory.returnIndex() + 1;
    const shownIndex = Math.ceil(currentIndex / this.options.slidesToScroll);
    if (this.$el.querySelector('.slider__current')) {
      this.$el.querySelector('.slider__current').innerHTML = shownIndex.toString().padStart(2, '0');
    }
  }

  onResize = () => {
    this.updateControls();
  }

  deconstructor() {
    const controls = this.$el.querySelectorAll('.slider__control');
    controls.forEach(($el) => {
      $el.remove();
    });
    this.$el.classList.remove('slider--initialized');
    this.$sliderFrame.classList.remove('slider__frame');
    this.$slidesContainer.classList.remove('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.removeAttribute('tabindex', null);
        }
        $item.classList.remove('slider__slide');
      });
    }

    if (this.lory) {
      this.lory.destroy();
    }
  }

  focusIn = (event) => {
    if (event.target) {
      const className = this.$slides[0].classList[0];
      this.$sliderFrame.scrollLeft = 0;
      const $slide = event.target.closest(`.${className}`);
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);
    }
  }
}
