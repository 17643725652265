import button from '../../atoms/button/button';

export default class Databox {
  constructor($databox) {
    this.$databox = $databox;
    this.$panels = this.$databox.querySelectorAll('.databox__panel');

    if (this.$panels?.length > 1) {
      this.$databox.addEventListener('selectTab', this.onSelectTab.bind(this));
    }

    this.initPanels();
    this.$databox.classList.add('databox--initialized');
  }

  initPanels() {
    this.$panels.forEach(($panel) => {
      const $items = $panel.querySelectorAll('.databox__list-item');
      const itemCount = $items.length;

      if (itemCount > 3) {
        $items.forEach(($item, index) => {
          if (index + 1 > 3) {
            $item.classList.add('databox__list-item--hidden');
          }
        });

        const $moreButton = button({
          style: 'secondary',
          link: false,
          icon: 'reload',
          title: false,
          text: 'Mehr laden',
          transparent: true,
          classes: 'databox__button',
        });

        $moreButton.addEventListener('click', e => this.loadMore(e, $moreButton, true));
        $panel.appendChild($moreButton);
      }
    });
  }

  loadMore = (event, $moreButton, loadAll = true) => {
    const $hiddenItems = $moreButton.parentNode
      .querySelector('.databox__list')
      .querySelectorAll('.databox__list-item--hidden');

    const $overviewButton = $moreButton.parentNode.querySelector(
      '.databox__button--overview',
    );

    const itemsToReveal = loadAll ? $hiddenItems.length : 3;

    for (let i = 0; i < itemsToReveal; i += 1) {
      if ($hiddenItems[i]) {
        $hiddenItems[i].classList.remove('databox__list-item--hidden');
      }
    }
    if ($overviewButton) {
      if (event.target.closest('.button') === $overviewButton) {
        $overviewButton.classList.add('databox__button--hidden');
      } else {
        $overviewButton.classList.remove('databox__button--hidden');
      }

      if (!$overviewButton.href) {
        $overviewButton.addEventListener('click', e => this.loadMore(e, $moreButton));
      }
    }

    $moreButton.classList.add('databox__button--hidden');
  }

  onSelectTab(event) {
    // Get current panel
    const $currentPanel = this.$databox.querySelector('.databox__panel:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = this.$databox.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.databox__panel');

    // Make panel visible
    $panel.removeAttribute('hidden');
    $headline.focus();
  }
}

document.querySelectorAll('.databox').forEach($el => new Databox($el));
