document.querySelectorAll('.calendar').forEach((wrap) => {
  const filter = wrap.querySelector('.calendar__filter');

  // Open filter
  const openFilterButton = wrap.querySelector('.calendar__open-filter');
  const openFilter = () => {
    filter.classList.add('calendar__filter--expanded');
  };
  openFilterButton.addEventListener('click', openFilter);

  // Close filter
  const closeFilterButton = wrap.querySelector('.calendar__filter__close');
  const closeFilter = () => {
    filter.classList.remove('calendar__filter--expanded');
  };
  closeFilterButton.addEventListener('click', closeFilter);

  // Load more
  const loadMoreButton = wrap.querySelector('.calendar__days__loadmore-button .button');
  let currentItems = 3;
  const loadMore = () => {
    const elementList = [...wrap.querySelectorAll('.calendar__day')];
    for (let i = currentItems; i < currentItems + 3; i += 1) {
      if (elementList[i]) {
        elementList[i].style.display = 'block';
      }
    }
    currentItems += 3;
    if (currentItems >= elementList.length) {
      loadMoreButton.style.display = 'none';
    }
  };
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', loadMore);
  }

  // Date picker
  const inputStartDate = wrap.querySelector('#start_date_created');
  const inputEndDate = wrap.querySelector('#end_date_created');
  const minEndDate = () => {
    inputEndDate.setAttribute('min', inputStartDate.value);
  };
  inputStartDate.addEventListener('change', minEndDate);
});
