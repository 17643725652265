import iframeResize from 'iframe-resizer/js/iframeResizer';

document.querySelectorAll('.iframe').forEach(() => { iframeResize(); });

// @TODO cmp events not working properly https://help.consentmanager.net/books/cmp/page/cmp-events

// __cmp('addEventListener', ['consentscreenoff', (e, o) => {
//   iframeResize();
// remove height from .iframe
// }, false], null);
